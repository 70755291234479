import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { ContentImage } from '../components/content/image';
import { ContentVideo } from '../components/content/video';
import { ContentExternalVideo } from '../components/content/externalVideo';
import { ContentText } from '../components/content/text';
import { DividerBlock } from '../components/dividerBlock';

const NewsArticle = ({ data }) => {
  const article = data.datoCmsSingleNews;
  const { news } = data;
  const buildContent = (obj) => {
    const type = obj.model.name;
    if (type === 'External Video') {
      return <ContentExternalVideo key={obj.id} video={obj.video} caption={obj.workCaption} />;
    }
    if (type === 'Image') {
      return <ContentImage key={obj.id} image={obj.image} caption={obj.workCaption} />;
    }
    if (type === 'Text') {
      return <ContentText key={obj.id} text={obj.text} />;
    }
    if (type === 'Video') {
      return <ContentVideo key={obj.id} video={obj.video.video.mp4Url} caption={obj.workCaption} />;
    }

    return null;
  };
  return (
    <Layout bgcolor={news.backgroundColor} wrapperClass="news-article" accentColor={news.accentColor}>
      <HelmetDatoCms seo={article.seoMetaTags} />
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <Link to="/news" className="sidebar-text">News</Link>
        </div>
        <div className="col-sm-8 col-xs-6 col">
          <h1
            className="gothic--large large-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: article.formattedTitle,
            }}
          />
        </div>
      </div>
      <div className="container row single-article-content">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <div className="img-wrapper">
            <Img
              fluid={{ ...article.featuredImage.fluid }}
              alt={article.featuredImage.alt}
            />
            <small className="caption">
              {article.featuredImage.title}
            </small>
          </div>
          <div className="main-body">
            <div
              className="content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: article.description,
              }}
            />
          </div>
        </div>
      </div>
      <div className="container row single-article-content">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col spacing-0">
          {article.additionalContent.length > 0
          && article.additionalContent.map((content) => (
            buildContent(content)
          ))}
        </div>
      </div>
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={news.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

NewsArticle.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default NewsArticle;

export const query = graphql`
  query SingleNewsArticleQuery($slug: String!) {
    news: datoCmsNews {
      accentColor {
        hex
      }
      backgroundColor
    }
    datoCmsSingleNews(slug: { eq: $slug }) {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      featuredImage {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsSizes_noBase64
        }
        title
      }
      formattedTitle
      description
      additionalContent {
        ... on DatoCmsImage {
          id
          model {
            name
          }
          image {
            title
            alt
            fluid(maxWidth: 700) {
              ...GatsbyDatoCmsSizes_noBase64
            }
          }
          workCaption
        }
        ... on DatoCmsText {
          id
          model {
            name
          }
          text
        }
        ... on DatoCmsVideo {
          id
          model {
            name
          }
          video {
            video {
              mp4Url
            }
          }
          workCaption
        }
        ... on DatoCmsExternalVideo {
          id
          model {
            name
          }
          video {
            providerUid
            title
          }
          workCaption
        }
      }
    }
  }
`;
